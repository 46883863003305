import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "movieItem w-full" }
const _hoisted_2 = {
  key: 0,
  class: "poster w-full aspect-[7/10] relative bg-neutral-900 border border-white border-opacity-5 rounded-md overflow-hidden"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  id: "formatAndReleaseStatus",
  class: "flex gap-2 text-white absolute top-0 left-0"
}
const _hoisted_5 = {
  key: 0,
  class: "text-xxs md:text-xs uppercase font-semibold px-2 py-1 rounded-br-md shadow-[0_0_8px_1px_rgba(0,0,0,0.3)] text-white bg-fuchsia-600"
}
const _hoisted_6 = {
  key: 1,
  class: "text-xxs md:text-xs uppercase font-semibold px-2 py-1 rounded-br-md shadow-[0_0_8px_1px_rgba(0,0,0,0.3)] text-white bg-blue-600"
}
const _hoisted_7 = {
  key: 1,
  class: "poster w-full aspect-[7/10] bg-neutral-900 rounded-md animate-pulse"
}
const _hoisted_8 = {
  key: 2,
  class: "text-white truncate font-semibold text-xs mt-2 opacity-75 lg:text-lg uppercase"
}
const _hoisted_9 = {
  key: 3,
  class: "h-4 w-full bg-neutral-900 rounded-sm mt-3 animate-pulse"
}
const _hoisted_10 = {
  key: 4,
  id: "formatAndReleaseStatus",
  class: "flex items-center gap-2 text-white mt-2 opacity-75"
}
const _hoisted_11 = {
  key: 0,
  class: "text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-gray-300 bg-neutral-900 border border-white border-opacity-5"
}
const _hoisted_12 = {
  key: 0,
  src: "/assets/img/M8.png",
  class: "h-[10px]",
  alt: "Magnify 8 Logo"
}
const _hoisted_13 = {
  key: 1,
  id: "format",
  class: "text-xs uppercase font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.poster)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "w-full h-full object-cover",
            src: $props.poster ?  $props.poster : '',
            alt: "poster"
          }, null, 8, _hoisted_3),
          ($props.release  || $props.preSale)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                ($props.preSale)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Preventa"))
                  : _createCommentVNode("", true),
                ($props.release)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Estreno"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7)),
    ($props.title && $props.showTitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString($props.title), 1))
      : ($props.showTitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9))
        : _createCommentVNode("", true),
    ($props.formato || $props.lenguaje)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          ($props.lenguaje)
            ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString($props.lenguaje.includes(',') ? $props.lenguaje.replace(', ', ' / ') : $props.lenguaje), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.formato, (format, i) => {
            return (_openBlock(), _createElementBlock("div", {
              id: "formatsContainer",
              key: i
            }, [
              (format == 'M8')
                ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                : (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(format), 1))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}