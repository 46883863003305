<template>
  <div
    ref="navbar"
    class="
      h-16
      flex
      gap-4
      py-4
      md:py-3
      items-center
      justify-between
      px-4
      lg:px-10
      border-b
      border-white
      border-opacity-5
    ">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
    name: 'NavBar',
}
</script>


