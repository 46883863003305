import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './css/tailwind.css'

//set webAppVersion
import packageJson from '../package.json';
store.state.webAppVersion = packageJson.version;

const app = createApp(App)

app.config.globalProperties.apiUrl =    (process.env.NODE_ENV === 'production')
                                        ? 'https://apiv2.gaf.adro.studio'
                                        : 'http://adronas.tplinkdns.com'

app.use(store).use(router)

// Replace -> app.mount('#app')
router.isReady().then(() => {
    app.mount('#app')
})

