import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  
  {
    path: '/',
    name: 'Inicio',
    component: Home
  },
  {
    path: '/admin',
    component: {
      template: '', // Componente vacío
    },
    beforeEnter() {
      window.location.href = 'https://gaf.adro.studio';
    }
  },
  {
    path: '/seleccionar-complejo',
    name: 'Seleccioanr Complejo',
    component: () => import('../views/cinemaSelect.vue')
  },
  {
    path: '/pelicula/:cinemaId/:pref',
    name: 'Pelicula',
    component: () => import('../views/MovieSingle.vue')
  },
  /*{
    path: '/entradas/:cinemaId/:fref',
    name: 'Entradas',
    component: () => import('../views/TicketsSelect.vue')
  },
  {
    path: '/butacas/:cinemaId/:fref',
    name: 'Butacas',
    component: () => import('../views/SeatsSelect.vue')
  },
  {
    path: '/candy/:cinemaId',
    name: 'Candy',
    component: () => import('../views/Candy.vue')
  },*/
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import('../views/Cart.vue'),
    children: [
      {
        path: '',
        redirect: '/',
      },
      {
        path: 'entradas',
        name: 'Entradas',
        component: () => import('../views/cart/Tickets.vue'),
        props: true
      },
      {
        path: 'butacas',
        name: 'Butacas',
        component: () => import('../views/cart/Seats.vue'),
      },
      {
        path: 'candy',
        name: 'Candy',
        component: () => import('../views/cart/Candy.vue'),
      }
    ]
  },  
  {
    path: '/pagar/:cinemaId/:salesId',
    name: 'Pagar',
    component: () => import('../views/Pay.vue')
  },
  {
    path: '/disconnected',
    name: 'Desconectado',
    component: () => import('../views/Disconnected.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: function() {
        return import ('../views/404.vue')
    }
  }
]

const router = createRouter({
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
  routes
})

export default router
