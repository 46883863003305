<template>
	<!-- movie -->
	<div class="movieItem w-full">
		<!-- poster -->
		<div v-if="poster" class="poster w-full aspect-[7/10] relative bg-neutral-900 border border-white border-opacity-5 rounded-md overflow-hidden">
			<img class="w-full h-full object-cover" :src="poster ?  poster : ''" alt="poster">
			<!-- format & releaseStatus -->
			<div id="formatAndReleaseStatus" class="flex gap-2 text-white absolute top-0 left-0" v-if="release  || preSale">
				<p class="text-xxs md:text-xs uppercase font-semibold px-2 py-1 rounded-br-md shadow-[0_0_8px_1px_rgba(0,0,0,0.3)] text-white bg-fuchsia-600" v-if="preSale">Preventa</p>
				<p class="text-xxs md:text-xs uppercase font-semibold px-2 py-1 rounded-br-md shadow-[0_0_8px_1px_rgba(0,0,0,0.3)] text-white bg-blue-600" v-if="release">Estreno</p>
			</div>
		</div>
		<!-- poster keleton -->
		<div v-else class="poster w-full aspect-[7/10] bg-neutral-900 rounded-md animate-pulse">
		</div>
		<!-- title -->
		<p v-if="title && showTitle" class="text-white truncate font-semibold text-xs mt-2 opacity-75 lg:text-lg uppercase">{{ title }}</p>
		<!-- title skeleton -->
		<p v-else-if="showTitle" class="h-4 w-full bg-neutral-900 rounded-sm mt-3 animate-pulse"></p>
		<!-- format & releaseStatus -->
		<div id="formatAndReleaseStatus" class="flex  items-center gap-2 text-white mt-2 opacity-75" v-if="formato || lenguaje">
			<p class="text-xxs uppercase font-semibold px-2 py-1 rounded-sm text-gray-300 bg-neutral-900 border border-white border-opacity-5" v-if="lenguaje">{{ lenguaje.includes(',') ? lenguaje.replace(', ', ' / ') : lenguaje}}</p>
			<!-- FORMAT -->
			<div id="formatsContainer" v-for="format,i in formato" :key="i">
				<!-- M8-->
				<img src="/assets/img/M8.png" class="h-[10px]" alt="Magnify 8 Logo" v-if="format == 'M8'">
				<!-- standar format -->
				<p id="format" class="text-xs uppercase font-bold" v-else>{{ format }}</p>
			</div>
		</div>
	</div>     
</template>

<script lang="ts">
import './components.css'

export default {
	name: 'MovieItem',
	props: {
		title: String,
		poster: String,
		formato: String,
		lenguaje: String,
		release: Boolean,
		preSale: Boolean,
		showTitle: {
			type: Boolean,
			default: true
		}
	},
	methods: {
		trimTitle: function(title: string){
			if(title.length > 30){
				return title.substring(0, 30) + '...'
			}else{
				return title
			}
		}
	}
}
</script>