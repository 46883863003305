//create store
import { createStore } from 'vuex'

//vuex persist
//import vuexPersistance from 'vuex-persist';

export default createStore({
    state: {
        webAppVersion: '',
        cinemas:[],
        cinemaInfo: {},
        cart: {},
        fref: '',
        functionFormat: '',
        kioskMode: false,
        showtimeId: 0
    },
    mutations: {
        setCinemaInfo(state, data) {
            state.cinemaInfo = data
        },
        setCart(state, data) {
            state.cart = data
        },
        setFref(state, data) {
            state.fref = data
        },
        setFunctionFormat(state, data) {
            state.functionFormat = data
        },
        setShowtimeId(state, data) {
            state.showtimeId = data
        }
    }
    /*,
    plugins:[
        new vuexPersistance({
            storage: window.localStorage
        }).plugin
    ]*/
})